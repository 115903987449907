.bootstrap-v5-wrapper {
    @import "./../../../../node_modules/bootstrap5/scss/bootstrap";

    //we do this to override ace bullshit
    @each $color, $value in $theme-colors {
        .badge-#{$color} {
            background-color: $value !important;
        }

        .btn-#{$color} {
            border-color: $value !important;
            background-color: $value !important;
        }
    }

    .modal {
        background-color: transparent
    }

    .fade {
        opacity: initial;
    }

    form {
        margin: initial
    }

    input[type=range]::-webkit-slider-thumb {
        background-color: #57c5f1 !important;
    }

    input[type=range]::-moz-range-thumb {
        background-color: #57c5f1 !important;
    }

    input[type=range]::-ms-thumb {
        background-color: #57c5f1 !important;
    }

    input[type="text"],
    input[type="file"],
    select {
        height: auto !important;
    }

    label,
    input,
    button,
    select,
    textarea {
        font-size: var(--bs-body-font-size) !important;
        font-weight: var(--bs-body-font-weight) !important;
        line-height: var(--bs-body-line-height) !important;
    }

    .nav-tabs {
        position: static !important;
        top: 0 !important;
        border-color: transparent !important;
    }

    .is-invalid~.invalid-feedback {
        display: block;
    }

    a:focus {
        outline: none !important;
    }

    label:not(.form-label) {
        margin: 0 !important;
    }

    input[type=text]:not(.form-control) {
        border: none !important;
        padding: 0 !important;
        font-size: inherit !important;
    }
}